import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

const textStyles = {
	h1: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 280px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	h2: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 208px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	h3: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 143px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	h4: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	h5: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	h6: fresponsive(css`
    font-family: Jubilee, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `),
	sh1: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 184px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -5.52px;
    text-transform: uppercase;
  `),
	sh2: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.2px;
    text-transform: uppercase;
  `),
	sh3: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.54px;
    text-transform: uppercase;
  `),
	sh4: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
    text-transform: uppercase;
  `),
	button: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
    text-transform: uppercase;
  `),
	kicker: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
    text-transform: uppercase;
  `),
	body: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 151%; /* 22.65px */
    letter-spacing: -0.45px;
  `),
	bodyL: fresponsive(css`
    font-family: "PP Mori", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 151%; /* 30.2px */
    letter-spacing: -0.6px;
    text-transform: uppercase;
  `),
}

export default textStyles
