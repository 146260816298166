import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import { ReactComponent as WSvg } from "images/global/icons/w.svg"
import { loader } from "library/Loader"
import { usePreloader } from "library/Loader/PreloaderUtils"
import UniversalImage from "library/UniversalImage"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Preloader() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const barRef = useRef<HTMLDivElement | null>(null)
	const [progress, setProgress] = useState(0)
	const wRef = useRef<HTMLDivElement | null>(null)

	loader.useEventListener("progressUpdated", (newProgress: number) => {
		setProgress(newProgress)
		gsap.set(barRef.current, {
			width: `${newProgress}%`,
		})
	})

	const slideOut = () => {
		gsap.to(wrapperRef.current, {
			y: "-100vh",
			duration: 1,
			delay: 0.5,
			ease: "power4.inOut",
		})
	}

	usePreloader({
		duration: 1.5,
		callback: slideOut,
	})

	useAnimation(() => {
		gsap.to(wRef.current, {
			y: 0,
			ease: "power3.out",
			duration: 2,
			delay: 0.5,
		})
	}, [])

	loader.useEventListener("end", () => {
		gsap.set(wrapperRef.current, { display: "none" })
	})

	const images: Queries.PreloaderQuery = useStaticQuery(graphql`
		query Preloader {
			flower: file(relativePath: { eq: "global/preloader.webp" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<Wrapper ref={wrapperRef}>
			<Content>
				<ImageWrapper>
					<Flower image={images.flower} alt="flower" />
					<WWrapper ref={wRef}>
						<W />
					</WWrapper>
				</ImageWrapper>
				<BarWrapper>
					<Bar>
						<BarTwo ref={barRef}>
							<Num>{Math.round(progress)}</Num>
						</BarTwo>
					</Bar>
				</BarWrapper>
			</Content>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${colors.rose01};
	z-index: 100;
	pointer-events: none;
	display: grid;
	place-items: center;
	${textStyles.h1}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 37px;
	`)}
`

const BarWrapper = styled.div`
	${fresponsive(css`
		width: 250px;
	`)}
`

const Bar = styled.div`
	position: relative;
	width: 100%;
	background-color: ${colors.rose03};

	${fresponsive(css`
		height: 2px;
	`)}
`

const BarTwo = styled(Bar)`
	background-color: ${colors.rose04};
	position: absolute;
	z-index: 2;
	width: 0%;
	top: 0;
	left: 0;
	transition: width 0.5s ease-out;
`

const Num = styled.div`
	${textStyles.kicker}
	color: ${colors.rose04};
	position: absolute;
	right: 0;

	${fresponsive(css`
		top: -13px;
	`)}
`

const ImageWrapper = styled.div`
	position: relative;
	overflow-y: clip;
`

const WWrapper = styled.div`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 150%);

	${fresponsive(css`
		bottom: 0;
	`)}
`

const W = styled(WSvg)`
	height: auto;

	${fresponsive(css`
		width: 300px;
	`)}
`

const Flower = styled(UniversalImage)`
	${fresponsive(css`
		width: 258px;
		height: 369px;
	`)}
`
