import { useScrollLock } from "library/Scroll"
import { type ReactNode, createContext, useState } from "react"

type ModalNames = "registry" | "rsvp" | "venmo"

export const ModalContext = createContext({
	activeModal: null as ModalNames | null,
	setActiveModal: (modal: ModalNames | null) => {},
})

export function ModalProvider({ children }: { children: ReactNode }) {
	const [activeModal, setActiveModal] = useState<ModalNames | null>(null)
	useScrollLock("lock", activeModal !== null)

	return (
		<ModalContext.Provider
			value={{
				activeModal,
				setActiveModal,
			}}
		>
			{children}
		</ModalContext.Provider>
	)
}
