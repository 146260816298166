import Button from "components/Buttons/Button"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import UniversalLink from "library/Loader/UniversalLink"
import { useScrollLock } from "library/Scroll"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { type FormEventHandler, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Rsvp() {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [going, setGoing] = useState<boolean | null>(null)
	const [guests, setGuests] = useState("")

	const [nameError, setNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [goingError, setGoingError] = useState(false)
	const [guestsError, setGuestsError] = useState(false)

	const formRef = useRef<HTMLFormElement | null>(null)
	const thankyouRef = useRef<HTMLDivElement | null>(null)

	const images: Queries.RsvpQuery = useStaticQuery(graphql`
		query Rsvp {
			ring: file(relativePath: { eq: "global/rsvp-ring.webp" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			flowers: file(relativePath: { eq: "global/rsvp-flowers.webp" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault()
		let error = false

		if (going === null) {
			setGoingError(true)
			error = true
		}
		if (!name) {
			setNameError(true)
			error = true
		}
		if (!email) {
			setEmailError(true)
			error = true
		}
		if (going && !guests) {
			setGuestsError(true)
			error = true
		}

		if (error) {
			return
		}

		const form = e.target as HTMLFormElement
		const formData = new FormData(form)

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			// @ts-expect-error form data moment
			body: new URLSearchParams(formData).toString(),
		})
			.then(() => {
				gsap.to(formRef.current, {
					opacity: 0,
					onComplete: () => {
						gsap.set(formRef.current, {
							display: "none",
						})
						gsap.set(thankyouRef.current, {
							display: "flex",
						})
						gsap.to(thankyouRef.current, {
							opacity: 1,
						})
					},
				})
			})
			.catch((error) => alert(error))
	}

	const [, setScrollUnlock] = useScrollLock("unlock")

	return (
		<Wrapper id="content-two">
			<Ring image={images.ring} alt="ring" />
			<Flowers image={images.flowers} alt="flowers" />
			<WeddingDate>August 31, 2024 4-9pm</WeddingDate>
			<Text>Mckenna Community Church</Text>
			<Text>4108 350th st s</Text>
			<Text>ROY, WA 98580</Text>
			<HR />
			<Form
				ref={formRef}
				data-netlify
				name="rsvp"
				onSubmit={onSubmit}
				onFocus={() => setScrollUnlock(true)}
				onBlur={() => setScrollUnlock(false)}
			>
				<input type="hidden" name="form-name" value="rsvp" />
				<input type="hidden" name="name" value={name} />
				<input type="hidden" name="email" value={email} />
				<input type="hidden" name="attending" value={String(going)} />
				<input type="hidden" name="guests" value={guests} />

				<Inputs>
					<Input
						$error={!!nameError}
						placeholder={nameError ? "Required" : "Your Name..."}
						value={name}
						onChange={(e) => {
							setName(e.target.value)
							setNameError(false)
						}}
					/>
					<Input
						$error={!!emailError}
						placeholder={emailError ? "Required" : "Your Email..."}
						value={email}
						onChange={(e) => {
							setEmail(e.target.value)
							setEmailError(false)
						}}
					/>
				</Inputs>
				<Inputs>
					<ToggleWrapper>
						<Label $error={!!goingError}>Will we see you there?</Label>
						<ToggleButtons>
							<ToggleButton
								$active={!!going}
								type="button"
								onClick={() => {
									setGoing(true)
									setGoingError(false)
								}}
							>
								Yes
							</ToggleButton>
							<ToggleButton
								$active={going === false}
								type="button"
								onClick={() => {
									setGoing(false)
									setGoingError(false)
								}}
							>
								No
							</ToggleButton>
						</ToggleButtons>
					</ToggleWrapper>
					<ToggleWrapper>
						<Label $error={guestsError}>
							How many additional guests will you be bringing?
						</Label>
						<Input
							placeholder="Enter Amount"
							value={guests}
							onChange={(e) => {
								setGuests(e.target.value)
								setGuestsError(false)
							}}
						/>
					</ToggleWrapper>
				</Inputs>
				<StyledButton type="submit">Submit Your RSVP</StyledButton>
			</Form>
			<Thankyou ref={thankyouRef}>
				<Title>Thank you!</Title>
				<P>
					We will update our guest list with your response. Thanks for taking
					the time to RSVP.
				</P>
			</Thankyou>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: none;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;

	${fresponsive(css`
		width: 606px;
		left: 111px;
		top: 114px;
		padding: 20px 0 100px;
	`)}

	${fmobile(css`
		padding: 13.5px 13.5px 100px;
		width: 311px;
		top: 114px;
		left: 24px;
	`)}
`

const Ring = styled(UniversalImage)`
	${fresponsive(css`
		width: 452px;
		height: 351px;
		margin-bottom: 67px;
	`)}

	${fmobile(css`
		width: 100%;
		height: auto;
		margin-bottom: 48px;
	`)}
`

const Flowers = styled(UniversalImage)`
	position: absolute;
	right: 0;

	${fresponsive(css`
		width: 403px;
		height: 575px;
		top: -53px;
	`)}

	${fmobile(css`
		width: 237px;
		height: 339px;
		right: -20px;
		top: -40px;
	`)}
`

const WeddingDate = styled.h1`
	position: relative;
	z-index: 4;
	color: ${colors.black};
	${textStyles.h5}

	${fresponsive(css`
		width: 352px;
		margin-bottom: 43px;
	`)}

  ${fmobile(css`
		${textStyles.h6}
		width: 188px;
	`)}
`

const Text = styled.p`
	color: ${colors.black};
	${textStyles.sh4}
`

const HR = styled.div`
	background-color: ${colors.black};
	width: 100%;
	height: 1px;

	${fresponsive(css`
		margin-top: 32px;
		margin-bottom: 55px;
	`)}
`

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	${fresponsive(css`
		gap: 18px;
		margin-bottom: 49px;
	`)}
`

const Input = styled.input<{ $error?: boolean }>`
	width: 100%;
	${textStyles.body}
	color: ${({ $error }) => ($error ? colors.rose02 : colors.black)};
	border-bottom: 1px solid ${colors.black};

	&::placeholder {
		color: ${({ $error }) => ($error ? colors.rose02 : colors.black)};
		${textStyles.body}
	}

	${fresponsive(css`
		height: 40px;
	`)}
`

const StyledButton = styled(Button)`
	background-color: ${colors.black};
	color: ${colors.white};
	width: fit-content;
	margin-left: auto;

	svg {
		path {
			stroke: ${colors.white};
		}
	}

	${fmobile(css`
		padding-right: 25px;
		padding-left: 25px;
	`)}
`

const ToggleWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${Input} {
		width: 212px;
	}

	${fmobile(css`
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 32px;
	`)}
`

const ToggleButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	${fresponsive(css`
		gap: 14px;
	`)}
`

const ToggleButton = styled(UniversalLink)<{ $active: boolean }>`
	width: fit-content;
	${textStyles.body}
	color: ${colors.black};
	border: 1px solid ${colors.black};
	border-radius: 99vw;
	background-color: ${({ $active }) =>
		$active ? colors.rose04 : "transparent"};

	${fresponsive(css`
		height: 43px;
		padding: 0 24px;
	`)}
`

const Label = styled.div<{ $error?: boolean }>`
	color: ${({ $error }) => ($error ? colors.rose02 : colors.black)};
	${textStyles.body}
`

const Thankyou = styled.div`
	display: none;
	opacity: 0;
	flex-direction: column;
	width: 100%;
	align-items: center;

	${fresponsive(css`
		gap: 10px;
	`)}
`

const Title = styled.h1`
	color: ${colors.black};
	${textStyles.h4}
`

const P = styled.p`
	color: ${colors.black};
	${textStyles.body}
	text-align: center;

	${fresponsive(css`
		width: 318px;
	`)}
`
