import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import UniversalLink from "library/Loader/UniversalLink"
import UniversalImage from "library/UniversalImage"
import { isMobileOS } from "library/deviceDetection"
import { fmobile, fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import useMedia from "library/useMedia"
import { useContext, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import Button from "./Buttons/Button"
import { ModalContext } from "./Providers/ModalContext"

export default function VenmoModal() {
	const { activeModal, setActiveModal } = useContext(ModalContext)
	const open = activeModal === "venmo"
	const closeModal = () => setActiveModal(null)

	const wrapperRef = useRef<HTMLDivElement | null>(null)
	const blurRef = useRef<HTMLDivElement | null>(null)
	const modalRef = useRef<HTMLDivElement | null>(null)

	const openTimeline = useAnimation(() => {
		const tl = gsap.timeline({
			paused: true,
			defaults: { ease: "power3.out" },
		})

		tl.to(
			wrapperRef.current,
			{
				display: "flex",
			},
			0,
		)

		tl.to(
			blurRef.current,
			{
				opacity: 1,
			},
			0,
		)

		tl.fromTo(
			modalRef.current,
			{
				xPercent: 110,
			},
			{
				xPercent: 0,
			},
			0,
		)

		return tl
	}, [])

	useEffect(() => {
		if (openTimeline) {
			if (open) {
				openTimeline.play()
			} else {
				openTimeline.reverse()
			}
		}
	}, [openTimeline, open])

	const images = useStaticQuery(graphql`
		query Venmo {
			qr: file(relativePath: { eq: "global/qr.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	const showQR = useMedia(true, true, true, !isMobileOS())
	const showButton = isMobileOS()
	const venmoLink = "https://venmo.com/u/darien711"

	return (
		<Wrapper ref={wrapperRef}>
			<Blur ref={blurRef} onClick={() => closeModal()} />
			<Modal ref={modalRef}>
				<CloseButton type="button" onClick={() => closeModal()}>
					Close
				</CloseButton>
				<Content>
					<Text>
						We are thrilled you want to support our honeymoon fund! You can
						contribute on Venmo{" "}
						{showQR
							? "with the Venmo QR Code or simply by username."
							: "with the link below or simply by username."}
					</Text>

					{showQR ? <Qr image={images.qr} alt="venmo qr code" /> : null}
					<Username>
						{showButton ? (
							<StyledButton to={venmoLink}>@darien711</StyledButton>
						) : (
							<UniversalLink to={venmoLink}>@darien711</UniversalLink>
						)}
					</Username>
				</Content>
			</Modal>
		</Wrapper>
	)
}

const StyledButton = styled(Button)`
	background-color: ${colors.black};
	color: ${colors.white};

	svg {
		path {
			stroke: ${colors.white};
		}
	}
`

const Wrapper = styled.div`
	position: fixed;
	z-index: 99;
	width: 100%;
	height: 100dvh;
	left: 0;
	top: 0;
	display: none;
	justify-content: flex-end;
	overflow: clip;

	${fresponsive(css`
		padding: 24px;
	`)}

	${fmobile(css`
		padding: 8px;
	`)}
`

const Blur = styled.div`
	position: absolute;
	z-index: 1;
	background: rgba(0 0 0 / 60%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`

const Modal = styled.div`
	position: relative;
	z-index: 2;
	background-color: ${colors.white};
	height: 100%;
	display: flex;
	flex-direction: column;
	transform: translateZ(0);
	overflow: clip scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	${fresponsive(css`
		width: 790px;
		border-radius: 24px;
		padding: 128px;
	`)}

	${fmobile(css`
		padding: 13.5px;
		height: 100%;
		width: 100%;
	`)}
`

const CloseButton = styled(UniversalLink)`
	color: ${colors.black};
	${textStyles.sh4};
	position: fixed;
	z-index: 100;

	${fresponsive(css`
		top: 32px;
		left: 32px;
	`)}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;

	${fresponsive(css`
		gap: 45px;
		width: 535px;
	`)}

	${fmobile(css`
		width: 330px;
		gap: 36px;
		margin-top: 96px;
	`)}
`

const Text = styled.p`
	color: ${colors.black};
	${textStyles.h6}
	text-align: center;
`

const Username = styled.div`
	color: ${colors.black};
	${textStyles.sh2}
	text-transform: unset;
	display: flex;
`

const Qr = styled(UniversalImage)`
	${fresponsive(css`
		width: 300px;
		height: auto;
	`)}

	${fmobile(css`
		width: 100%;
		height: auto;
	`)}
`
