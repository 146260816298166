import { graphql, useStaticQuery } from "gatsby"
import { useScrollLock } from "library/Scroll"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import Button from "../Buttons/Button"

const DEFAULT_PLACEHOLDER = "Enter Password..."

export default function PasswordContent({
	onValid,
}: {
	onValid: VoidFunction
}) {
	const [password, setPassword] = useState("")
	const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER)
	const [error, setError] = useState(false)

	const submitPassword = () => {
		if (password === process.env.GATSBY_RSVP_PASSWORD) {
			onValid()
		} else if (!password) {
			setError(true)
			setPassword("")
			setPlaceholder("Required")
		} else {
			setError(true)
			setPassword("")
			setPlaceholder("Incorrect Password")
		}
	}

	const images: Queries.PasswordQuery = useStaticQuery(graphql`
		query Password {
			flowers: file(relativePath: { eq: "global/password.webp" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	const [, setScrollUnlock] = useScrollLock("unlock")

	return (
		<Wrapper
			id="content-one"
			onSubmit={(e) => {
				e.preventDefault()
				submitPassword()
			}}
		>
			<Flowers image={images.flowers} alt="flower" />
			<Title>
				To RSVP, please enter the password that is on your invitation.
			</Title>
			<Input
				$error={error}
				placeholder={placeholder}
				value={password}
				onChange={(e) => {
					setError(false)
					setPlaceholder(DEFAULT_PLACEHOLDER)
					setPassword(e.target.value)
				}}
				onFocus={() => setScrollUnlock(true)}
				onBlur={() => setScrollUnlock(false)}
			/>
			<StyledButton type="submit">Submit Password</StyledButton>
		</Wrapper>
	)
}

const Wrapper = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 3;

	${fresponsive(css`
		top: 80px;
		padding: 13.5px;
	`)}
`

const Title = styled.h1`
	color: ${colors.black};
	${textStyles.h5}
	text-align: center;

	${fresponsive(css`
		width: 535px;
		margin-bottom: 89px;
	`)}

	${fmobile(css`
		width: 100%;
		${textStyles.h6}
	`)}
`

const Input = styled.input<{ $error: boolean }>`
	border-bottom: 1px solid
		${({ $error }) => ($error ? colors.rose02 : colors.black)};
	text-align: center;
	${textStyles.sh3}
	color: ${({ $error }) => ($error ? colors.rose02 : colors.black)};
	outline: 0;
	outline: none;
	text-transform: none !important;

	&:focus {
		outline: 0;
		outline: none;
		${textStyles.sh3}
	}

	&::placeholder {
		color: ${colors.black} ${textStyles.sh3};
	}

	${fresponsive(css`
		width: 540px;
		height: 60px;
		margin-bottom: 50px;
	`)}

	${fmobile(css`
		width: 100%;
	`)}
`

const StyledButton = styled(Button)`
	background-color: ${colors.black};
	color: ${colors.white};

	svg {
		path {
			stroke: ${colors.white};
		}
	}
`

const Flowers = styled(UniversalImage)`
	${fresponsive(css`
		width: 181px;
		height: 169px;
		margin-bottom: 30px;
	`)}
`
