import gsap from "gsap"
import UniversalLink from "library/Loader/UniversalLink"
import { ScreenContext } from "library/ScreenContext"
import { fmobile, fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useContext, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"
import Button from "./Buttons/Button"
import { ModalContext } from "./Providers/ModalContext"

export default function RegsitryModal() {
	const { activeModal, setActiveModal } = useContext(ModalContext)
	const open = activeModal === "registry"
	const closeModal = () => setActiveModal(null)
	const { mobile } = useContext(ScreenContext)

	const wrapperRef = useRef<HTMLDivElement | null>(null)
	const blurRef = useRef<HTMLDivElement | null>(null)
	const modalRef = useRef<HTMLDivElement | null>(null)

	const openTimeline = useAnimation(() => {
		const tl = gsap.timeline({
			paused: true,
			defaults: { ease: "power3.out" },
		})

		tl.to(
			wrapperRef.current,
			{
				display: "flex",
			},
			0,
		)

		tl.to(
			blurRef.current,
			{
				opacity: 1,
			},
			0,
		)

		tl.fromTo(
			modalRef.current,
			{
				xPercent: 110,
			},
			{
				xPercent: 0,
			},
			0,
		)

		return tl
	}, [])

	useEffect(() => {
		if (openTimeline) {
			if (open) {
				openTimeline.play()
			} else {
				openTimeline.reverse()
			}
		}
	}, [openTimeline, open])

	return (
		<Wrapper ref={wrapperRef}>
			<Blur ref={blurRef} onClick={() => closeModal()} />
			<Modal ref={modalRef}>
				<CloseButton type="button" onClick={() => closeModal()}>
					Close
				</CloseButton>
				<Content>
					<Text>
						Thank you so much for considering a gift for us at one of these
						places
					</Text>
					<StyledButton to={links.potteryBarn}>
						{mobile ? "Pottery Barn" : "See our pottery barn registry"}
					</StyledButton>
					<StyledButton to={links.amazon}>
						{mobile ? "Amazon" : "See our amazon registry"}
					</StyledButton>
				</Content>
			</Modal>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	z-index: 99;
	width: 100%;
	height: 100dvh;
	left: 0;
	top: 0;
	display: none;
	justify-content: flex-end;
	overflow: clip;

	${fresponsive(css`
		padding: 24px;
	`)}

	${fmobile(css`
		padding: 8px;
	`)}
`

const Blur = styled.div`
	position: absolute;
	z-index: 1;
	background: rgba(0 0 0 / 60%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`

const Modal = styled.div`
	position: relative;
	z-index: 2;
	background-color: ${colors.white};
	height: 100%;
	display: flex;
	flex-direction: column;
	transform: translateZ(0);
	overflow-y: auto;
	overscroll-behavior: contain;

	&::-webkit-scrollbar {
		display: none;
	}

	${fresponsive(css`
		width: 790px;
		border-radius: 24px;
		padding: 32px;
	`)}

	${fmobile(css`
		padding: 13.5px;
		width: 100%;
	`)}
`

const CloseButton = styled(UniversalLink)`
	color: ${colors.black};
	${textStyles.sh4};
	position: fixed;
	z-index: 100;

	${fresponsive(css`
		top: 32px;
		left: 32px;
	`)}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;

	${fresponsive(css`
		margin-top: 128px;
		gap: 92px;
		width: 535px;
	`)}

	${fmobile(css`
		margin-top: 96px;
		width: 330px;
		gap: 36px;
	`)}
`

const Text = styled.p`
	color: ${colors.black};
	${textStyles.h6}
	text-align: center;
`

const StyledButton = styled(Button)`
	background-color: ${colors.black};
	color: ${colors.white};

	svg {
		path {
			stroke: ${colors.white};
		}
	}
`
