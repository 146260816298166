const routes = {
	home: "/",
} as const

const links = {
	amazon: "https://www.amazon.com/wedding/registry/2ECVU70W1R62I",
	potteryBarn:
		"https://www.potterybarn.com/registry/pc8vjn6rmn/registry-list.html",
	...routes,
} as const

export default links
