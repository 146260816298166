import { ReactComponent as ArrowSVG } from "images/global/icons/arrow.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { fmobile, fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Button({
	className = "",
	children,
	...props
}: UniversalLinkProps) {
	return (
		<Wrapper className={className} {...props}>
			<Mask>
				<Inner>
					{children}
					<Arrow />
				</Inner>
				<Inner>
					{children}
					<Arrow />
				</Inner>
			</Mask>
		</Wrapper>
	)
}

const Inner = styled.div`
	display: flex;
	align-items: center;
	transition: transform 0.5s;

	${fresponsive(css`
		gap: 10px;
	`)}
`

const Wrapper = styled(UniversalLink)`
	${textStyles.button};
	border-radius: 99vw;
	background-color: ${colors.white};
	color: ${colors.rose02};

	&:hover {
		${Inner} {
			${fresponsive(css`
				transform: translateY(calc(-100% - 20px));
			`)}
		}
	}

	${fresponsive(css`
		height: 70px;
		padding: 26px 48px;
	`)}

	${fmobile(css`
		padding: 26px 40px;
	`)}
`

const Arrow = styled(ArrowSVG)`
	height: auto;

	path {
		stroke: ${colors.rose02};
	}

	${fresponsive(css`
		width: 27px;
	`)}
`

const Mask = styled.div`
	display: flex;
	width: fit-content;
	overflow: clip;
	flex-direction: column;
	height: 100%;

	${fresponsive(css`
		gap: 20px;
		height: 20px;
	`)}
`
