import { graphql, useStaticQuery } from "gatsby"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { useContext } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { desktopBreakpoint } from "styles/media"
import textStyles from "styles/text"
import Link from "./Buttons/Link"
import { ModalContext } from "./Providers/ModalContext"

export default function Footer() {
	const { setActiveModal } = useContext(ModalContext)

	const images: Queries.FooterQuery = useStaticQuery(graphql`
		query Footer {
			flower: file(relativePath: { eq: "global/footer-flower.webp" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<Wrapper>
			<Inner>
				<Flower image={images.flower} alt="flower" />
				<Links>
					<Link type="button" onClick={() => setActiveModal("rsvp")}>
						RSVP
					</Link>
					<Link type="button" onClick={() => setActiveModal("registry")}>
						Our Registry
					</Link>
					<Link type="button" onClick={() => setActiveModal("venmo")}>
						Honeymoon Fund
					</Link>
				</Links>
				<EventDate>August 31, 2024</EventDate>
				<HeadingOne>The Willardson</HeadingOne>
				<HeadingTwo>Wedding</HeadingTwo>
				<Bottom>
					<Kicker>Designed and Built by Eric Hoffman</Kicker>
					<Kicker>Reform Collective 2024</Kicker>
				</Bottom>
			</Inner>
		</Wrapper>
	)
}

const Wrapper = styled.footer`
	background-color: ${colors.rose02};
	width: 100%;
	display: grid;
	place-items: center;
`

const Inner = styled.div`
	width: 100%;
	max-width: ${desktopBreakpoint}px;
	position: relative;
	overflow: clip;

	${fresponsive(css`
		height: 750px;
	`)}

	${fmobile(css`
		height: 520px;
	`)}
`

const Flower = styled(UniversalImage)`
	position: absolute;
	z-index: 2;

	${fresponsive(css`
		left: 380px;
		top: -58px;
		width: 696px;
		height: 994px;
	`)}

	${ftablet(css`
		left: 164px;
	`)}

  ${fmobile(css`
		width: 375px;
		height: 536px;
		left: 0;
		top: unset;
		bottom: -20px;
	`)}
`

const Links = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	align-items: flex-end;
	z-index: 2;

	${fresponsive(css`
		gap: 27px;
		right: 30px;
		top: 285px;
	`)}

	${fmobile(css`
		top: 50px;
		left: 16px;
		align-items: flex-start;
		display: none;
	`)}
`

const Heading = styled.h1`
	position: absolute;
	${textStyles.h3}
	color: ${colors.rose05};

	${ftablet(css`
		${textStyles.h4}
	`)}

	${fmobile(css`
		${textStyles.h5}
	`)}
`

const HeadingOne = styled(Heading)`
	z-index: 3;

	${fresponsive(css`
		left: 50px;
		bottom: 30px;
	`)}

	${fmobile(css`
		left: 16px;
		bottom: 150px;
	`)}
`

const HeadingTwo = styled(Heading)`
	z-index: 1;

	${fresponsive(css`
		right: 50px;
		bottom: 30px;
	`)}

	${fmobile(css`
		z-index: 3;
		left: 16px;
		right: unset;
		bottom: 77px;
	`)}
`

const EventDate = styled.div`
	position: absolute;
	z-index: 3;
	${textStyles.sh3}
	color: ${colors.rose05};

	${fresponsive(css`
		bottom: 160px;
		left: 693px;
	`)}

	${ftablet(css`
		bottom: 100px;
		left: 533px;
	`)}

  ${fmobile(css`
		bottom: 237px;
		right: 16px;
		left: unset;
	`)}
`

const Bottom = styled.div`
	position: absolute;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${fresponsive(css`
		border-top: 1px solid ${colors.rose05};
		padding-top: 10px;
		padding-bottom: 27px;
		left: 50px;
		bottom: 0;
		width: 1340px;
	`)}

	${ftablet(css`
		width: 924px;
	`)}

  ${fmobile(css`
		bottom: 0;
		width: 343px;
		left: 16px;
	`)}
`

const Kicker = styled.span`
	${textStyles.kicker}
	color: ${colors.rose05};
`
