import Link from "components/Buttons/Link"
import { ReactComponent as LogoSVG } from "images/global/icons/w.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import { useContext } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { desktopBreakpoint } from "styles/media"
import textStyles from "styles/text"
import { ModalContext } from "./Providers/ModalContext"

export default function Header() {
	const { setActiveModal } = useContext(ModalContext)

	const compact = useMedia(false, false, false, true)

	return (
		<Wrapper>
			<Inner>
				{compact ? (
					<>
						<Logo />
					</>
				) : (
					<>
						<StyledLink
							type="button"
							onClick={() => setActiveModal("registry")}
						>
							Our Registry
						</StyledLink>
						<Title>{"The Willardson\nWedding"}</Title>
					</>
				)}
				<StyledLink type="button" onClick={() => setActiveModal("rsvp")}>
					RSVP
				</StyledLink>
			</Inner>
		</Wrapper>
	)
}

const Logo = styled(LogoSVG)`
	${fresponsive(css`
		height: 40px;

		path {
			fill: ${colors.rose01} !important;
		}
	`)}
`

const Wrapper = styled.header`
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	width: 100%;
	display: grid;
	place-items: center;
`

const Inner = styled.div`
	width: 100%;
	max-width: ${desktopBreakpoint}px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${fresponsive(css`
		height: 106px;
		padding: 0 50px;
	`)}

	${fmobile(css`
		padding: 30px;
	`)}
`

const StyledLink = styled(Link)`
	color: ${colors.black};
	${textStyles.sh3}

	svg {
		height: auto;

		path {
			stroke: ${colors.black};
		}

		${fresponsive(css`
			width: 23px;
		`)}
	}
`

const Title = styled.div`
	${textStyles.h6}
	color: ${colors.rose02};

	${fmobile(css`
		white-space: pre;
	`)}
`
