import { createGlobalStyle } from "styled-components"

/**
 * place all your colors here! the format is:
 * [hex color, optional p3 color]
 *
 * if you provide a p3 color, it will be used where supported
 */
const rawColors = {
	rose01: ["#5C1818", "color(display-p3 0.3294 0.1137 0.1059)"],
	rose02: ["#893534", "color(display-p3 0.498 0.2275 0.2157)"],
	rose03: ["#AF3F3F", "color(display-p3 0.6353 0.2784 0.2627)"],
	rose04: ["#EAB590", "color(display-p3 0.8863 0.7176 0.5843)"],
	rose05: ["#F3CDB1", "color(display-p3 0.9294 0.8078 0.7098)"],
	blue: ["#23455B", "color(display-p3 0.1686 0.2667 0.349)"],
	black: ["#000", "color(display-p3 0 0 0)"],
	white: ["#FFF", "color(display-p3 1 1 1)"],
} as const satisfies Record<string, [string, string] | [string]>

export const ColorStyle = createGlobalStyle`
	:root {
		@supports (not (color: color(display-p3 0 0 0))) {
			${Object.entries(rawColors).map(([key, [hex]]) => {
				return `--${key}: ${hex};`
			})}
		}

		@supports (color: color(display-p3 0 0 0)) {
			${Object.entries(rawColors).map(([key, [hex, p3]]) => {
				return `--${key}: ${p3 ?? hex};`
			})}
		}
	}
`

/**
 * convert the raw colors to an object with the correct color for the current browser
 */
const CSSColors = Object.fromEntries(
	Object.entries(rawColors as Record<string, [string, string] | [string]>).map(
		([key]) => {
			return [key, `var(--${key})`]
		},
	),
) as {
	[key in keyof typeof rawColors]: `var(--${key})`
}

/**
 * gsap can't animate variables, so we need to use the hex always
 */
const jsColors = Object.fromEntries(
	Object.entries(rawColors as Record<string, [string, string] | [string]>).map(
		([key, [color]]) => {
			return [key, color]
		},
	),
) as {
	[key in keyof typeof rawColors]: (typeof rawColors)[key][0]
}

export default {
	...CSSColors,
	js: jsColors,
}
