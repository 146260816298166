import { ModalContext } from "components/Providers/ModalContext"
import gsap from "gsap"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useContext, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import PasswordContent from "./Password"
import RsvpContent from "./Rsvp"

export default function RsvpModal() {
	const { activeModal, setActiveModal } = useContext(ModalContext)
	const open = activeModal === "rsvp"
	const closeModal = () => setActiveModal(null)

	const wrapperRef = useRef<HTMLDivElement | null>(null)
	const blurRef = useRef<HTMLDivElement | null>(null)
	const modalRef = useRef<HTMLDivElement | null>(null)

	const openTimeline = useAnimation(() => {
		const tl = gsap.timeline({
			paused: true,
			defaults: { ease: "power3.out" },
		})

		tl.to(
			wrapperRef.current,
			{
				display: "flex",
			},
			0,
		)

		tl.to(
			blurRef.current,
			{
				opacity: 1,
			},
			0,
		)

		tl.fromTo(
			modalRef.current,
			{
				xPercent: 110,
			},
			{
				xPercent: 0,
			},
			0,
		)

		return tl
	}, [])

	useEffect(() => {
		if (openTimeline) {
			if (open) {
				openTimeline.play()
			} else {
				openTimeline.reverse()
			}
		}
	}, [openTimeline, open])

	return (
		<Wrapper ref={wrapperRef}>
			<Blur ref={blurRef} onClick={() => closeModal()} />
			<Modal ref={modalRef}>
				<CloseButton type="button" onClick={() => closeModal()}>
					Close
				</CloseButton>
				<PasswordContent
					onValid={() => {
						gsap.set("#content-two", { display: "flex" })
						gsap.from("#content-two", {
							opacity: 0,
							yPercent: 150,
							ease: "power3.inOut",
						})
						gsap.to("#content-one", {
							opacity: 0,
							yPercent: -150,
							ease: "power3.inOut",
						})
					}}
				/>
				<RsvpContent />
			</Modal>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	z-index: 99;
	width: 100%;
	height: 100dvh;
	left: 0;
	bottom: 0;
	display: none;
	justify-content: flex-end;
	overflow: clip;

	${fresponsive(css`
		padding: 24px;
	`)}

	${fmobile(css`
		padding: 8px;
	`)}
`

const Blur = styled.div`
	position: absolute;
	z-index: 1;
	background: rgba(0 0 0 / 60%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`

const Modal = styled.div`
	position: relative;
	z-index: 2;
	background-color: ${colors.white};
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overscroll-behavior: contain;
	transform: translateZ(0);

	&::-webkit-scrollbar {
		display: none;
	}

	${fresponsive(css`
		width: 790px;
		border-radius: 24px;
		padding: 32px;
	`)}

	${fmobile(css`
		width: 100%;
		padding: 13.5px;
	`)}
`

const CloseButton = styled(UniversalLink)`
	color: ${colors.black};
	${textStyles.sh4};
	position: fixed;
	z-index: 100;

	${fresponsive(css`
		top: 32px;
		left: 32px;
	`)}
`
