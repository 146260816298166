import { ReactComponent as ArrowSVG } from "images/global/icons/arrow.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Link({
	children,
	className = "",
	...props
}: UniversalLinkProps) {
	return (
		<Wrapper className={className} {...props}>
			<Mask>
				<Inner>
					{children}
					<Arrow />
				</Inner>
				<Inner>
					{children}
					<Arrow />
				</Inner>
			</Mask>
		</Wrapper>
	)
}

const Inner = styled.div`
	display: flex;
	align-items: center;
	transition: transform 0.5s;

	${fresponsive(css`
		gap: 10px;
	`)}
`

const Wrapper = styled(UniversalLink)`
	display: flex;
	align-items: center;
	${textStyles.button}
	color: ${colors.rose04};

	&:hover {
		${Inner} {
			${fresponsive(css`
				transform: translateY(calc(-100% - 20px));
			`)}
		}
	}
`

const Arrow = styled(ArrowSVG)`
	height: auto;
	position: relative;

	path {
		stroke: ${colors.rose04};
	}

	${fresponsive(css`
		top: -2px;
		width: 20px;
	`)}
`

const Mask = styled.div`
	display: flex;
	width: fit-content;
	overflow: clip;
	flex-direction: column;
	height: 100%;

	${fresponsive(css`
		gap: 20px;
		height: 20px;
	`)}
`
